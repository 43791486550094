import Layout from "../components/layout";
import { Card, CardMedia, CardContent, Container } from '@mui/material';
import { Link } from "gatsby";
import RemoteInclusiveAnimation from '../components/Search/remote_inclusive_bg.mp4';
import IntroducingImage from '../articles/introducing_remoteinclusive_small.jpg';
import OurStoryImage from '../articles/our_story_small.jpg';
import Logo from '../components/remoteinclusive_logo.svg';
import useScroll from "../utils/scrollHook";
import { useEffect, useState } from "react";

import '../components/press.scss';

const Press = () => {
  const {scrollY} = useScroll();
  const [scalePercentage, setScalePercentage] = useState(0);
  useEffect(() => {
    if (document && document.body) {
      const percentage = scrollY / document.body.scrollHeight;
      const scalePercentage = 1 + (1 * percentage);
      setScalePercentage(scalePercentage);
    }
  }, [scrollY]);
  return (
    <Layout description="RemoteInclusive - Inclusive, Remote and Transparent Job Listings - Pay Ranges and DEI Policy Required" pageTitle="RemoteInclusive - Inclusive, Remote and Transparent Job Listings">
      <div className="press-header">
        <div className="press-header__container">
          <video style={{transform: `scale(${scalePercentage})`}} autoPlay={true} muted={true} loop={true} id="searchAnimation" className="press-header__video">
            <source src={RemoteInclusiveAnimation} type="video/mp4" />
          </video>
        </div>
      </div>
      <div className="press-container">
        <Container maxWidth="lg">
          <div className="press-title">
            <div className="press-container__title">
              <img src={Logo} alt="logo" />RemoteInclusive
            </div>
            <p>The market has spoken. We're tearing down the walls that keep candidates from applying to your open positions. Show them you mean business!</p>
          </div>
          <div className="press__section-title">
            News
          </div>
          <div className="press__latest-news">
            <Link to="/articles/introducing-remote-inclusive">
              <Card className="press__card">
                <CardMedia
                  component="img"
                  height="140"
                  src={IntroducingImage}
                  alt="introducing remote inclusive" />
                <CardContent>
                  <h3>Introducing Remote Inclusive</h3>
                </CardContent>
              </Card>
            </Link>
            <Link to="/articles/our-story">
              <Card className="press__card">
                <CardMedia
                  component="img"
                  height="140"
                  src={OurStoryImage}
                  alt="our story" />
                <CardContent>
                  <h3>Our Story - Why Remote Work and Inclusion are our core values</h3>
                </CardContent>
              </Card>
            </Link>
          </div>
          <div className="press__section-title">
            Brand & Style Guidelines
          </div>
          <div className="press__brand">
            <article>
              <span className="press__brand-title">Brand Colors</span>
              <p className="press__brand-sub-title">RI Deep Blue is the main brand color and should be used in whatever you share about RemoteInclusive. RI Deep Blue can be used with any other brand color to add dimension, depth, and contrast.</p>
              <div className="press__brand-colors">
                <div className="press__brand-color" style={{backgroundColor: "#0073de"}}>
                  <span>RI Deep Blue</span>
                  <span>Backgrounds, Foregrounds</span>
                  <span>#0073de</span>
                </div>
                <div className="press__brand-color" style={{backgroundColor: "#aad2f8", color: "#292929"}}>
                  <span>RI Light Blue</span>
                  <span>Typography</span>
                  <span>#aad2f8</span>
                </div>
                <div className="press__brand-color" style={{backgroundColor: "#0063B1"}}>
                  <span>RI Dark Blue</span>
                  <span>Fills, background</span>
                  <span>#0063B1</span>
                </div>
                <div className="press__brand-color" style={{backgroundColor: "#EA005E"}}>
                  <span>RI Magenta</span>
                  <span>Fills, foreground</span>
                  <span>#EA005E</span>
                </div>
                <div className="press__brand-color" style={{backgroundColor: "#E3008C"}}>
                  <span>RI Pink</span>
                  <span>Fills, contrast, depth</span>
                  <span>#E3008C</span>
                </div>
                <div className="press__brand-color" style={{backgroundColor: "#ccaaff", color: "#292929"}}>
                  <span>RI Light Purple</span>
                  <span>Subtitles, Alternative Typography</span>
                  <span>#ccaaff</span>
                </div>
                <div className="press__brand-color" style={{backgroundColor: "#d7e1e6", color: "#292929"}}>
                  <span>RI Light Grey</span>
                  <span>Titles, Typography</span>
                  <span>#d7e1e6</span>
                </div>
                <div className="press__brand-color" style={{backgroundColor: "#292929"}}>
                  <span>RI Dark Grey</span>
                  <span>Backgrounds</span>
                  <span>#292929</span>
                </div>
              </div>
            </article>
            <article>
              <span className="press__brand-title">Logos and Images</span>
              <p className="press__brand-sub-title">Logo 180x180 PNG</p>
              <p>
                <img src="/img/apple-touch-icon-180x180.png" alt="logo 180x180" />
              </p>
              <p className="press__brand-sub-title">Logo SVG</p>
              <p>
                <img style={{height: "60px", width: "60px"}} src="/img/favicon.svg" alt="logo 180x180" />
              </p>
              <p className="press__brand-sub-title">Header / Branding Image</p>
              <p>
                <img src="/img/remoteinclusive_meta_img.jpg" alt="header and branding" />
              </p>
            </article>
          </div>
        </Container>
      </div>
    </Layout>
  )
};

export default Press;
